/**
 * Created by Andrey Popov on 30.06.2020.
 */

var ComboBarView = cc.Node.extend({
    ctor: function (combo) {
        this._super();

        this.combo = combo;

        this.updateSize();
        this.setPositionRound(cleverapps.styles.ComboBarView.position);
        this.setAnchorPoint(0.5, 0.5);

        this.initSlots();

        cleverapps.tooltipManager.create(this, {
            text: "StreakTooltip",
            location: cleverapps.UI.Tooltip.LOCATION_BELOW,
            size: cleverapps.styles.UI.Tooltip.SIZE.long
        });

        this.onChangeLevel();

        combo.on("updateGoal", this.updateGoal.bind(this));
        combo.on("changeLevel", this.onChangeLevel.bind(this));
        combo.on("reward", this.reward.bind(this));
    },

    updateSize: function () {
        var styles = cleverapps.styles.ComboBarView;
        this.setContentSize(styles);
        this.setScale(styles.scale[cleverapps.resolution.mode]);
    },

    initSlots: function () {
        var styles = cleverapps.styles.ComboBarView;

        var row = new cc.Node();
        row.setPositionRound(styles.row);
        this.progressSlots = [];

        for (var i = 0; i < cleverapps.Combo.COMBO_LEVEL_WITH_PRIZE; i++) {
            var slot = new cc.Node();
            slot.setPosition(i * styles.slot.margin + styles.slot.offset.x, styles.slot.offset.y);
            row.addChild(slot);
            this.progressSlots.push(slot);
        }

        var bg = cleverapps.UI.createSprite(bundles.game.frames.combo_back_png);
        bg.setPosition(this.width / 2, this.height / 2);
        bg.setContentSize(styles);
        bg.setLocalZOrder(-1);
        bg.addChild(row);
        this.addChild(bg);

        this.goalSlot = new cc.Node();
        this.goalSlot.setPosition(this.width + styles.goal.offsetX, styles.goal.offsetY);
        this.updateGoal();
        this.addChild(this.goalSlot);
    },

    onChangeLevel: function () {
        if (cleverapps.config.wysiwygMode && Game.currentGame.stopped) {
            return;
        }

        this.progressSlots.forEach(function (slot, slotIndex) {
            if (slot.icon && slotIndex >= this.combo.level) {
                slot.icon.runAction(new cc.Sequence(new cc.ScaleTo(0.1, 0), new cc.RemoveSelf()));
                slot.icon = undefined;
            }

            if (slot.icon === undefined && slotIndex < this.combo.level) {
                this.drawProgressPoint(slot, slotIndex);
            }
        }.bind(this));
    },

    drawProgressPoint: function (slot, slotIndex) {
        var progressPointImage = cleverapps.styles.ComboBarView.progressImages[this.combo.progressPoints[slotIndex]];

        var pointSprite = slot.icon = cleverapps.UI.createSprite(progressPointImage);
        pointSprite.setPositionRound(cleverapps.styles.ComboBarView.point);
        slot.addChild(pointSprite);

        pointSprite.runAction(
            new cc.Sequence(
                new cc.Hide(),
                new cc.ScaleTo(0, 0),
                new cc.DelayTime(0.2),
                new cc.Show(),
                new cc.ScaleTo(0.2, 1.4).easing(cc.easeBackOut()),
                new cc.ScaleTo(0.1, 1)
            )
        );

        var goalLight = new cleverapps.Spine(bundles.game.jsons.combo_light_json);
        goalLight.setAnimation(0, "animation", false);
        goalLight.setPositionRound(this.goalView.width / 2, this.goalView.height / 2);
        goalLight.setCompleteListenerRemove();
        this.goalView.addChild(goalLight);

        this.goalView.runAction(new cc.Sequence(
            new cc.ScaleTo(0.15, 1.2),
            new cc.ScaleTo(0.1, 0.9),
            new cc.ScaleTo(0.1, 1.1),
            new cc.ScaleTo(0.1, 1)
        ));
    },

    updateGoal: function () {
        if (this.goalView) {
            this.goalView.removeFromParent();
            this.goalView = undefined;
        }

        var goalSprite = bundles.game.frames.combo_goal_png;

        switch (this.combo.currentGoal) {
            case 0: goalSprite = bundles.card.frames.combo_default_png; break;
            case 1: goalSprite = bundles.card.frames.combo2_default_png; break;
        }

        this.goalView = cleverapps.UI.createSprite(goalSprite);
        this.goalSlot.addChild(this.goalView);

        this.goalView.setScale(0);
        this.goalView.runAction(new cc.Sequence(new cc.DelayTime(0.2), new cc.ScaleTo(0.3, 1)));
    },

    reward: function (card) {
        var styles = cleverapps.styles.ComboBarView.bonus;
        var ViewClass = card.getViewClass();
        var view = new ViewClass(card);
        if (this.goalView) {
            this.goalView.visible = false;
        }
        this.addChild(view);
        view.setPosition(this.width + styles.offsetX, styles.offsetY);
        view.setScale(styles.scale);
        view.setRotation(styles.rotation);
        cleverapps.audio.playSound(bundles.game.urls.collected_effect);
    }
});

cleverapps.styles.ComboBarView = {
    width: 308,
    height: 98,

    scale: [1, 0.9, 1],

    position: [{
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -124 }
    }, {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -134 }
    }, {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -134 }
    }],

    goal: {
        offsetX: -31,
        offsetY: 58
    },

    row: {
        x: { align: "left", dx: 40 },
        y: { align: "center" }
    },

    slot: {
        offset: {
            x: -1,
            y: 2
        },
        margin: 53
    },

    bonus: {
        offsetX: -31,
        offsetY: 58,
        rotation: -170,
        scale: 0.42
    },

    progressImages: {},

    point: {
        x: { align: "center", dx: 3 },
        y: { align: "center", dy: 3 }
    }
};

cleverapps.styles.ComboBarView.progressImages[Card.SUIT_HEARTS] = bundles.game.frames.progress_point_h;
cleverapps.styles.ComboBarView.progressImages[Card.SUIT_CLUBS] = bundles.game.frames.progress_point_c;
cleverapps.styles.ComboBarView.progressImages[Card.SUIT_DIAMONDS] = bundles.game.frames.progress_point_d;
cleverapps.styles.ComboBarView.progressImages[Card.SUIT_SPADES] = bundles.game.frames.progress_point_s;
