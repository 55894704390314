/**
 * Created by vladislav on 17.09.2020
 */

var ProgressView = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.hoverContainer = new cc.Node();
        this.hoverContainer.setAnchorPoint(0.5, 0.5);
        this.addChild(this.hoverContainer);

        this.addProgressBar();
        this.addArrows();
        this.addIcons();
        cleverapps.UI.wrap(this.hoverContainer);
        this.setContentSize2(this.hoverContainer.getContentSize());
        this.hoverContainer.setPositionRound(this.width / 2, this.height / 2);

        cleverapps.UI.onClick(this.hoverContainer, this.onClick.bind(this));

        var position = cleverapps.styles.ProgressView.positions || cleverapps.styles.ProgressView;
        this.setPositionRound(position);

        this.setCascadeOpacityEnabledRecursively(true);

        cleverapps.aims.registerTarget("metaStar", this, {
            controls: ["progress_view", "play_button"],
            withoutDelta: true,
            noTargetDelta: true,
            flyingUnderShadow: true,
            flyingAnimation: Reward.NO_ANIMATION
        });

        cleverapps.meta.getMainObject().on("updateProgress", this.updateProgress.bind(this), this);
        cleverapps.meta.getMainObject().on("showForce", this.showForce.bind(this), this);

        SceneDecors.add(this.hoverContainer, cleverapps.skins.getSlot("progressViewDecor"));
    },

    onClick: function () {
        if (cleverapps.forces.isRunningForce()) {
            cleverapps.forces.closeRunningForce();
        }

        cleverapps.audio.playSound(bundles.main.urls.click_effect);

        if (!cleverapps.config.rpg) {
            cleverapps.focusManager.display({
                focus: "BackgroundsWindow",
                action: function (f) {
                    new BackgroundsWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
    },

    showForce: function () {
        cleverapps.forces.create(this.hoverContainer, Forces.SIMPLE);
    },

    runProgressAnimation: function () {
        var styles = cleverapps.styles.ProgressView;

        var progressBarAnimation = new cleverapps.Spine(bundles.simple.jsons.progress_bar_spark_json);
        this.hoverContainer.addChild(progressBarAnimation);
        progressBarAnimation.setPositionRound(styles.bar.spark);
        progressBarAnimation.runAction(new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.CallFunc(function () {
                progressBarAnimation.setAnimation(0, "animation", false);
                progressBarAnimation.setCompleteListenerRemove();
            })
        ));
    },

    runAvatarAnimation: function () {
        if (cleverapps.config.rpg) {
            return;
        }

        var avatarAnimation = new cleverapps.Spine(bundles.simple.jsons.avatar_spark_json);
        this.hoverContainer.addChild(avatarAnimation);
        avatarAnimation.setPositionRound(this.nextIcon.getPosition());
        avatarAnimation.setCompleteListenerRemove();
        avatarAnimation.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.CallFunc(function () {
                avatarAnimation.setAnimation(0, "animation", false);
                cleverapps.audio.playSound(bundles.simple.urls.simple_win_effect);
            })
        ));
    },

    runStarAnimation: function (delay, timeScale, speed, callback) {
        var styles = cleverapps.styles.ProgressView;

        var scene = cleverapps.scenes.getRunningScene();

        var target = this.progressBar.bar.barText.items[0];
        var targetPos = scene.convertToNodeSpace(target.getParent().convertToWorldSpace(target.getPosition()));

        targetPos.x += styles.star.x;
        targetPos.y += styles.star.y;

        var star = new cleverapps.Spine(bundles.simple.jsons.star_json);
        scene.addChild(star);
        star.setPositionRound(targetPos.x, scene.height / 2);
        star.setLocalZOrder(10);
        star.setTimeScale(timeScale);

        star.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.CallFunc(function () {
                star.setAnimation(0, "collect", false);
                star.setCompleteListenerRemove();
            }),
            new cc.MoveTo(speed, targetPos).easing(cc.easeIn(2)),
            new cc.PlaySound(bundles.simple.urls.star_collect_effect),
            new cc.CallFunc(function () {
                this.progressBar.updateProgress(1);

                callback();
            }.bind(this))
        ));
    },

    updateProgress: function (addedStars, callback) {
        var styles = cleverapps.styles.ProgressView;

        var controls = ["progress_view"];
        if (!cleverapps.meta.getMainObject().checkCompleted()) {
            controls.push("play_button");
        }
        cleverapps.focusManager.showControlsWhileFocused(controls);

        var onStarsFinished = cleverapps.wait(addedStars, function () {
            if (!cleverapps.meta.isCompleted() && cleverapps.meta.getMainObject().checkCompleted()) {
                this.runProgressAnimation();

                this.runAvatarAnimation();
            }

            var actions = AnimationsLibrary.animateDelta(addedStars, {
                target: this.progressBar,
                x: styles.delta.x,
                y: styles.delta.y,
                font: cleverapps.styles.FONTS.SCENE_ANIMATE_DELTA_TEXT
            });

            if (callback) {
                actions = new cc.Spawn(
                    actions,
                    new cc.Sequence(
                        new cc.DelayTime(1.2),
                        new cc.CallFunc(callback)
                    )
                );
            }

            this.runAction(actions);
        }.bind(this));

        var maxTime = 5;
        var baseDelay = 0.4;

        var delay = baseDelay;
        if (baseDelay * addedStars > maxTime) {
            delay = maxTime / addedStars;
        }

        var timeScale = baseDelay / delay;
        var speed = delay / baseDelay;

        for (var i = 0; i < addedStars; i++) {
            this.runStarAnimation(i * delay, timeScale, speed, onStarsFinished);
        }
    },

    addProgressBar: function () {
        var styles = cleverapps.styles.ProgressView;

        this.progressBar = new StarsProgressBar({
            width: styles.bar.width,
            stars: cleverapps.meta.getMainObject().pendingsStars > 0 ? cleverapps.meta.stars - cleverapps.meta.getMainObject().pendingsStars : undefined
        });
        this.hoverContainer.addChild(this.progressBar);
    },

    addArrows: function () {
        var styles = cleverapps.styles.ProgressView.arrows;

        if (!styles) {
            return;
        }

        var arrowWidth = new cc.Sprite(bundles.simple.frames.arrow_png).width;
        var amount = (this.progressBar.width - styles.widthOffset) / (arrowWidth + styles.margin);

        var arrows = [];
        for (var i = 0; i < amount; i++) {
            arrows.push(new cc.Sprite(bundles.simple.frames.arrow_png));
        }

        var layout = new cleverapps.Layout(arrows, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        layout.setLocalZOrder(-1);

        this.progressBar.bar.addChild(layout);
        layout.setPositionRound(styles);
    },

    addIcons: function () {
        if (cleverapps.config.rpg) {
            return;
        }

        var styles = cleverapps.styles.ProgressView;

        var currentIcon = this.currentIcon = this.createIcon();
        this.hoverContainer.addChild(currentIcon);
        currentIcon.setPositionRound(-styles.bar.width / 2 - styles.icon.offset.x, this.height / 2 + styles.icon.offset.y);
        currentIcon.setRotation(-styles.icon.rotation || 0);

        var nextIcon = this.nextIcon = this.createIcon(true);
        nextIcon.setRotation(styles.icon.rotation || 0);
        this.hoverContainer.addChild(nextIcon);
        nextIcon.setPositionRound(styles.bar.width / 2 + styles.icon.offset.x, this.height / 2 + styles.icon.offset.y);
        if (cleverapps.meta.selectedLocationId === cleverapps.meta.getLastLocationId()) {
            nextIcon.addChild(this.createLock());
        }
    },

    createIcon: function (isNext) {
        var icon;
        if (isNext) {
            icon = new cc.Sprite(bundles[cleverapps.meta.getMainObject().getIconBundle(cleverapps.meta.selectedLocationId + 1)].urls.icon);
        } else {
            icon = new cc.Sprite(bundles[cleverapps.meta.getMainObject().getIconBundle(cleverapps.meta.selectedLocationId)].urls.icon);
        }

        var iconBg = this.createIconBg(icon, isNext);

        var node = new cc.Node();
        node.setAnchorPoint(0.5, 0.5);
        node.setContentSize2(iconBg.getContentSize());
        node.addChild(icon);
        node.addChild(iconBg);
        node.setLocalZOrder(10);

        icon.setPositionRound(node.width / 2, node.height / 2);

        return node;
    },

    createLock: function () {
        var styles = cleverapps.styles.ProgressView;

        var lock = new cc.Sprite(bundles.simple.frames.lock_png);
        lock.setLocalZOrder(10);
        lock.setScale(styles.comingSoon.lock.scale);
        lock.setPositionRound(styles.comingSoon.lock);

        return lock;
    },

    createIconBg: function (icon, isNext) {
        var styles = cleverapps.styles.ProgressView.icon.bg;

        var iconBg = new cc.Scale9Sprite(isNext ? bundles.simple.frames.frame_next_png || bundles.simple.frames.frame_silver_png : bundles.simple.frames.frame_current_png || bundles.simple.frames.frame_gold_png);

        if (styles.flipped) {
            styles = styles.flipped;

            if (isNext) {
                iconBg.setScaleX(-1);
            }
            iconBg.setPositionRound(isNext ? styles.right : styles.left);
        } else {
            iconBg.setContentSize2(icon.width * icon.scaleX + 2 * styles.padding.x, icon.height * icon.scaleY + 2 * styles.padding.y);
            iconBg.setPositionRound(styles);
        }

        return iconBg;
    },

    showAnimation: function (silent) {
        this.setVisible(true);

        if (silent) {
            this.hoverContainer.setScale(1);
            this.hoverContainer.setOpacity(255);
            return;
        }

        this.hoverContainer.setScale(0.6);
        this.hoverContainer.setOpacity(0);

        this.hoverContainer.runAction(new cc.Spawn(
            new cc.ScaleTo(0.15, 1).easing(cc.easeBackOut()),
            new cc.FadeIn(0.15)
        ));
    },

    hideAnimation: function (silent) {
        if (silent) {
            this.setVisible(false);
            return;
        }

        this.hoverContainer.runAction(new cc.Spawn(
            new cc.ScaleTo(0.15, 0.6).easing(cc.easeBackIn()),
            new cc.FadeOut(0.15)
        ));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.15),
            new cc.Hide()
        ));
    }
});

cleverapps.styles.ProgressView = {
    x: { align: "center" },
    y: { align: "bottom", dy: 250 },

    bar: {
        width: 350,

        spark: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    },

    star: {
        x: 0,
        y: -12
    },

    arrows: {
        x: { align: "center" },
        y: { align: "center", dy: 4 },
        widthOffset: 40,
        margin: 15
    },

    icon: {
        offset: {
            y: 6,
            x: 52
        },
        bg: {
            padding: {
                x: 11,
                y: 12
            },
            x: { align: "center" },
            y: { align: "center", dy: -2 }
        }
    },

    comingSoon: {
        lock: {
            x: { align: "center" },
            y: { align: "center" },
            scale: 0.3
        },
        icon: {
            width: 96,
            height: 92
        }
    },

    delta: {
        x: { align: "center" },
        y: { align: "top", dy: 50 }
    }
};
