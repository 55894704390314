/**
 * Created by mac on 2/24/21
 */

cleverapps.override(cleverapps.Tool.game, {
    openCard: function () {
        if (Game.currentGame.open.isFull()) {
            return;
        }

        var missed = Game.currentGame.table.listMissed();
        if (missed.length !== 0) {
            Game.currentGame.table.startPlayCard(missed[0]);
        }
    },

    addJackpot: function () {
        Game.currentGame.addJackpot(function () {});
    },

    movesReset: function () {
        while (!Game.currentGame.hand.isEmpty()) {
            Game.currentGame.shiftCard();
        }
    },

    addHardReward: function () {
        Game.currentGame.addHardReward(10);
    },

    nextScreen: function () {
        var page = Game.currentGame.pagination.getCurrent();

        var screen = Game.currentGame.level.content.screens[page];

        Game.currentGame.table.setCards([], [], screen.maxValue);
        Game.currentGame.table.setRect();
        Game.currentGame.table.updateSize();
        Game.currentGame.counter.trigger();
    }
});

cleverapps.override(cleverapps.Tool.Debug, {
    toggleGoldOnCards: function () {
        cleverapps.flags.goldOnCardsDisabled = !cleverapps.flags.goldOnCardsDisabled;

        cleverapps.notification.create((cleverapps.flags.goldOnCardsDisabled ? "Disabled" : "Enabled") + " level gold");
    }
});
