/**
 * Created by andrey on 12.12.19.
 */

var DailyLevel = function () {
    if (cleverapps.isLocalhost()) {
        delete DailyLevel.AVAILABLE.source;
    }

    this.load();
};

DailyLevel.prototype.load = function () {
    if (!this.isAvailable()) {
        return;
    }

    var event = cleverapps.getRequestParameters().event;
    if (event && event.indexOf(DailyLevel.EVENT_NAME) === 0) {
        this.currentEvent = event.substr(DailyLevel.EVENT_NAME.length);
        this.startFromPortlet = true;
    }

    this.currentEvent = this.currentEvent || this.getCurrentEvent();

    var info = cleverapps.dataLoader.load(DataLoader.TYPES.DAILY_LEVEL);
    this.passed = info && info.event === this.currentEvent && (!info.levelsToPass || info.levelsToPass === 0);
    this.levelsToPass = info && info.levelsToPass ? info.levelsToPass : this.getCountOfLevelsForReward();

    if (cleverapps.config.debugMode && ["576593702177"].indexOf(connector.platform.getUserID()) !== -1) {
        this.passed = false;
    }
};

DailyLevel.prototype.start = function (f) {
    var levelNo = this.getCurrentLevel();
    var level = new Level(Level.LevelTypes.DAILY_LEVELS, levelNo);

    MethaHelper.start(f, level);
};

DailyLevel.prototype.isAvailable = function () {
    return cleverapps.user.checkAvailable(DailyLevel.AVAILABLE) && this.getBundle();
};

DailyLevel.prototype.getBundle = function () {
    return bundles.episode_daily_levels_ru || bundles.episode_daily_levels;
};

DailyLevel.prototype.isActive = function () {
    return this.isAvailable() && !this.passed;
};

DailyLevel.prototype.isStartFromPortlet = function () {
    return this.isAvailable() && this.startFromPortlet;
};

DailyLevel.prototype.reset = function () {
    this.passed = false;
    this.levelsToPass = this.getCountOfLevelsForReward();
    cleverapps.dataLoader.remove(DataLoader.TYPES.DAILY_LEVEL);
};

DailyLevel.prototype.onWin = function (level) {
    this.levelsToPass--;

    cleverapps.dataLoader.save(DataLoader.TYPES.DAILY_LEVEL, {
        event: this.getCurrentEvent(),
        levelsToPass: this.levelsToPass
    });

    if (this.levelsToPass === 0) {
        this.passed = true;

        if (connector.platform.oneOf(connector.OK)) {
            cleverapps.platformLogic.reportStatus(DailyLevel.EVENT_NAME + this.getCurrentEvent(), this.getStatus(level));
        }

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DAILY_LEVEL_PASSED + this.getCurrentEvent());
    }
};

DailyLevel.prototype.getStatus = function () {
    var statuses = Messages.get("DailyLevel.Status");
    return statuses[Math.floor(Math.random() * statuses.length)];
};

DailyLevel.prototype.getCurrentEvent = function () {
    if (this.currentEvent) {
        return this.currentEvent;
    }

    var now = new Date();
    var year = cleverapps.padZeroes(now.getFullYear() % 100, 2);
    var mm = cleverapps.padZeroes(now.getMonth() + 1, 2);
    var dd = cleverapps.padZeroes(now.getDate(), 2);
    return year + mm + dd;
};

DailyLevel.prototype.getCurrentLevel = function () {
    var event = this.getCurrentEvent();
    var year = event.substr(0, 2);
    var mm = event.substr(2, 2);
    var dd = event.substr(4, 2);

    var date = new Date();
    date.setUTCFullYear(parseInt("20" + year), parseInt(mm) - 1, parseInt(dd));
    date.setUTCHours(0, 0, 0, 0);

    var offset = date.getTime() - new Date("2019-12-17").getTime();
    offset /= 24 * 60 * 60 * 1000;
    var daysPassed = Math.floor(offset);

    var levelsAmount = this.getBundle().meta.episode.levelsAmount - this.getCountOfLevelsForReward() + 1;
    var levelNo = (daysPassed % levelsAmount + levelsAmount) % levelsAmount;
    console.log("dailylevel.getCurrentLevel event " + event + " levelNo " + levelNo);
    return levelNo + (this.getCountOfLevelsForReward() - this.levelsToPass);
};

DailyLevel.prototype.getCountOfLevelsForReward = function () {
    return 1;
};

DailyLevel.EVENT_NAME = "dailylevel";

DailyLevel.AVAILABLE = {
    projectName: ["wordsoup", "crocword", "olympics", "tripeaks", "spades"],
    language: "ru",
    source: ["ok"]
};

cleverapps.InitByFeature["dailylevel"] = function () {
    cleverapps.dailyLevel = new DailyLevel();
};