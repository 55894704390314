/**
 * Created by vladislav on 17.09.2020
 */

var Simple = function (locationId) {
    BaseLocation.call(this, locationId);

    this.pendingsStars = 0;
    cleverapps.meta.on("changeStars", this.onChangeStars.bind(this), this);
};

Simple.prototype = Object.create(BaseLocation.prototype);
Simple.prototype.constructor = Simple;

Simple.prototype.onChangeStars = function (stars) {
    if (stars > 0) {
        this.pendingsStars += stars;
    }

    if (cleverapps.meta.stars >= this.getRequiredStars() && cleverapps.meta.currentLocationId < cleverapps.meta.getLastLocationId()) {
        this.complete();
    }
};

Simple.prototype.getBundles = function () {
    return [
        this.getBackgroundBundle(this.locationId),
        this.getIconBundle(this.locationId),
        this.getIconBundle(this.locationId + 1),
        this.getPostMoveActionBundle()
    ].filter(Boolean);
};

Simple.prototype.getIconBundle = function (index) {
    if (index >= cleverapps.meta.listAllLocationIds().length || cleverapps.config.rpg) {
        return "simple_background_icon_coming_soon";
    }

    return "simple_background_icon_" + index;
};

Simple.prototype.getBackgroundBundle = function (index) {
    if (cleverapps.config.rpg) {
        return;
    }

    return [
        "simple_background_" + index,
        (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? "simple_background_vertical_" : "simple_background_horizontal_") + index,
        "simple_background_" + cleverapps.config.orientation + "_" + index
    ].find(function (bundleName) {
        return bundles[bundleName];
    });
};

Simple.prototype.getPostMoveActionBundle = function () {
    if (cleverapps.config.type === "tile3") {
        if (TileGenerator.getNewSkins().length) {
            return "tiles_unlock_window";
        }
    }
};

Simple.prototype.onComplete = function (f) {
    cleverapps.focusManager.compound(f, [
        function (f) {
            if (this.pendingsStars > 0) {
                this.updateProgress(f);
            } else {
                f();
            }
        }.bind(this),
        function (f) {
            if (this.isForceAvailable()) {
                this.showForce(f);
            } else if (cleverapps.config.rpg) {
                var scene = cleverapps.scenes.getRunningScene();
                scene.completeCurrentPage(f);
            } else {
                f();
            }
        }.bind(this)
    ]);
};

Simple.prototype.onOpen = function (f) {
    var scene = cleverapps.scenes.getRunningScene();
    cleverapps.focusManager.compound(f, [
        function (f) {
            cleverapps.meta.resetStars();
            var bundles = this.getBundles();
            if (bundles) {
                cleverapps.bundleLoader.loadBundles(bundles);
            }
            connector.social.markAchievement({});
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SIMPLE_METHA_LEVELUP);
            f();
        }.bind(this),
        function (f) {
            if (cleverapps.config.rpg) {
                scene.startNextPage(f);
            } else {
                new BackgroundsWindow({ moveNext: true });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        },
        function (f) {
            if (cleverapps.config.rpg) {
                f();
            } else {
                scene.moveNextBg(f);
            }
        },
        function (f) {
            cleverapps.meta.afterMoveNext(f);
        }
    ]);
};

Simple.prototype.updateProgress = function (f) {
    var pendingsStars = this.pendingsStars;
    this.trigger("updateProgress", pendingsStars, f);
    this.pendingsStars = 0;
};

Simple.prototype.amountLevelStars = function (level) {
    if (level.isHard()) {
        return 3;
    }
    if (level.isTricky()) {
        return 2;
    }
    return 1;
};

Simple.prototype.getRequiredStars = function () {
    var current = cleverapps.meta.currentLocationId;

    var stars = [5, 10, 20, 30, 30, 40, 40, 40, 40, 40].concat(
        [50, 50, 50, 50, 50, 70, 70, 70, 70, 70]
    ).concat(
        [100, 100, 100, 100, 100, 125, 125, 125, 125, 125]
    ).concat(
        [150, 150, 150, 150, 150, 175, 175, 175, 175, 175]
    ).concat(
        [200, 200, 200, 200, 200, 225, 225, 225, 225, 225]
    );

    if (cleverapps.config.rpg) {
        stars = [5, 10, 15, 20, 25];
    }

    if (current < stars.length) {
        return stars[current];
    }

    return stars[stars.length - 1];
};

Simple.prototype.isForceAvailable = function () {
    return cleverapps.user.episode === 0 && !cleverapps.forces.isShown(Forces.SIMPLE.id) && cleverapps.config.type !== "match3";
};

Simple.prototype.showForce = function (f) {
    this.trigger("showForce");
    cleverapps.forces.onceForceClosed = f;
};

Simple.prototype.calcReward = function (splitToRewards) {
    var rewards = cleverapps.config.soft ? { soft: 50 } : { hard: 50 };
    rewards.boosters = {};

    var found = 0;
    var boosters = cleverapps.boosters.listBoostersBefore();
    if (cleverapps.config.name === "differences") {
        boosters = cleverapps.boosters.listBoosters();
    }

    boosters.forEach(function (booster) {
        if (found < 3 && booster.isAvailable()) {
            rewards.boosters[booster.id] = 1;
            found++;
        }
    });

    if (splitToRewards) {
        var rewardsList = [];
        for (var type in rewards) {
            var reward = rewards[type];
            if (type === "boosters") {
                rewardsList = rewardsList.concat(Object.keys(reward).map(function (id) {
                    return {
                        type: "boosters",
                        value: {
                            id: id,
                            amount: rewards.boosters[id]
                        }
                    };
                }));
            } else {
                rewardsList.push({ type: type, value: reward });
            }
        }
        return rewardsList;
    }

    return rewards;
};

Simple.prototype.processChatMessage = function (message) {
    if (!message.metha.simple) {
        return;
    }
    cleverapps.meta.currentLocationId = message.metha.simple.current;
    cleverapps.meta.resetStars();
};

Simple.listLocationsIds = function () {
    var ids = [];
    var id = 0;

    var exist = function () {
        if (cleverapps.config.rpg) {
            return bundles["episode_" + id];
        }

        return bundles["simple_background_" + id]
            || bundles["simple_background_horizontal_" + id]
            || bundles["simple_background_vertical_" + id];
    };

    while (exist()) {
        ids.push(id);
        id++;
    }

    return ids;
};

Simple.listActiveLocationsIds = BaseLocation.listActiveLocationsIds;