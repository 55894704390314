/**
 * Created by mac on 2/25/20
 */

var OpenCards = function (level, save) {
    cleverapps.EventEmitter.call(this);

    this.counter = new Counter();
    if (cleverapps.config.type === "tile3") {
        this.counter.registerStage(0, this.explode.bind(this));
    }

    this.counter.registerStage(1, function () {
        Game.currentGame.counter.trigger();
    });

    this.cards = [];
    this.toExplode = [];

    this.limit = cleverapps.gameModes.threeOpenCards ? 3 : 7;

    if (save) {
        this.loadSave(save);
    }
    this.getView = function () {};

    this.debugLabel = "OpenCards";
};

OpenCards.prototype = Object.create(cleverapps.EventEmitter.prototype);
OpenCards.prototype.constructor = OpenCards;

OpenCards.prototype.getCardPosition = function (card) {
    var position = {};

    var view = this.getView();
    if (!view) {
        return position;
    }

    position.parent = view;
    var index = this.cards.indexOf(card);
    position.point = view.findPosForCard(index);
    return position;
};

OpenCards.prototype.loadSave = function (data) {
    data.forEach(function (item) {
        var card = TileFactory.Create(item, {
            noMark: true
        });
        card.visibleIndex = item.visibleIndex;
        this.addCard(card, card.visibleIndex);
        card.flip();
    }.bind(this));
};

OpenCards.prototype.getInfo = function () {
    return this.cards.map(function (card) {
        return card.toJSON();
    });
};

OpenCards.prototype.addExtraSlot = function () {
    this.extraSlot = new ExtraSlot(this);
    this.trigger("addExtraSlot", this.extraSlot);
    this.checkWarning();
};

OpenCards.prototype.incLimit = function () {
    this.limit += 1;
};

OpenCards.prototype.explode = function () {
    if (!this.toExplode.length) {
        return;
    }
    var toExplode = this.toExplode.splice(-3);
    var index = this.cards.indexOf(toExplode[0]);
    var isLast = Game.currentGame.table.isEmpty() && this.cards.length === 3;
    this.cards.splice(index, 3);
    this.trigger("explode", toExplode, isLast);
    var delay = isLast ? 0.8 : 0;

    this.counter.setTimeout(function () {
        var needReindex = this.cards.some(function (card) {
            return card.visibleIndex !== index;
        });
        if (needReindex) {
            this.reindex();
            this.counter.setTimeout(function () {}, this.getReindexTimeout(3) * 1000 + 50);
        }
    }.bind(this), (delay + Card.EXPLODE_TIMEOUT) * 1000);

    Game.currentGame.log.clear();
};

OpenCards.prototype.isExploding = function (card) {
    return this.toExplode.indexOf(card) !== -1;
};

OpenCards.prototype.checkOverflow = function () {
    if (this.counter.isActive()) {
        return;
    }

    if (this.isFull()) {
        this.trigger("overflow");
        return true;
    }
    this.checkWarning();
};

OpenCards.prototype.checkWarning = function () {
    var warning = this.cards.length >= this.limit - 2;
    this.trigger("warning", warning);
};

OpenCards.prototype.getReindexTimeout = function (deltaIndex) {
    return (deltaIndex + 3) / 3 * 0.1;
};

OpenCards.prototype.reindex = function () {
    for (var i = 0; i < this.cards.length; i++) {
        if (this.cards[i].visibleIndex !== i) {
            var timeout = this.getReindexTimeout(Math.abs(this.cards[i].visibleIndex - i));
            this.cards[i].visibleIndex = i;
            this.moveToOpen(this.cards[i], timeout);
        }
    }
};

OpenCards.prototype.moveToOpen = function (card, duration, fromTable) {
    card.trigger("moveToOpen", duration, fromTable);
};

OpenCards.prototype.isEmpty = function () {
    return this.cards.length === 0;
};

OpenCards.prototype.isFull = function () {
    return this.cards.length === this.limit;
};

OpenCards.prototype.canPlay = function (card) {
    return !this.isFull() && card.canPlay();
};

OpenCards.prototype.acceptCard = function (card) {
    card.collectMarksAndComponents();
    if (!card.isOpen()) {
        card.flip();
    }

    var flyDuration = Math.max((card.y + 600) / 3000, 0.1) + 0.1;
    var index = this.cards.length;

    for (var i = index - 1; i >= 0; i--) {
        if (this.cards[i].value === card.value && !this.isExploding(this.cards[i])) {
            index = i + 1;

            if (this.cards[i - 1] && this.cards[i - 1].value === card.value && !this.isExploding(this.cards[i - 1])) {
                this.toExplode = [this.cards[i - 1], this.cards[i], card].concat(this.toExplode);
            }
            break;
        }
    }
    if (index !== this.cards.length) {
        cleverapps.timeouts.setTimeout(this.reindex.bind(this), Math.max(flyDuration - this.getReindexTimeout(1), 0) * 1000);
    }

    this.addCard(card, index);

    this.moveToOpen(card, flyDuration, true);
    this.counter.setTimeout(function () {
    }, (flyDuration + 0.05) * 1000);
};

OpenCards.prototype.addCard = function (card, index) {
    index = index || this.cards.length;
    this.cards.splice(index, 0, card);
    card.visibleIndex = index;
    card.setOwner(this);
};

OpenCards.prototype.cardClicked = function () {
    if (cleverapps.config.debugMode && !this.counter.isActive() && !this.isFull()) {
        var game = Game.currentGame;

        var table = game.table;
        var cards = table.cards;

        for (var i = cards.length - 1; i >= 0; i--) {
            var card = cards[i];

            if (!card.isOpen() || card.feature === "safe" || card.feature === "streak") {
                continue;
            }

            var plateOnCard = card.findComponent(PlateOnCard);
            if (plateOnCard) {
                plateOnCard.collect();
            }

            if (typeof FlagsOnCard !== "undefined") {
                var flagsOnCard = card.findComponent(FlagsOnCard);
                flagsOnCard && flagsOnCard.destroy();
            }

            if (typeof IceOnCard !== "undefined") {
                var iceOnCard = card.findComponent(IceOnCard);
                iceOnCard && iceOnCard.destroy();
            }

            table.startPlayCard(card);

            break;
        }
    }
    return false;
};

OpenCards.prototype.shift3Cards = function () {
    var cards = this.cards.splice(0, 3);
    if (!cards) {
        return;
    }
    cards.forEach(function (card) {
        card.setOwner(Game.currentGame.table);
        this.trigger("pop", card);
    }.bind(this));
    cleverapps.timeouts.setTimeout(this.reindex.bind(this), this.getReindexTimeout(3) * 1000);
    return cards;
};

OpenCards.prototype.pop = function (move) {
    var value = move.options.value;

    var index = undefined;
    for (var i = this.cards.length - 1; i >= 0; i--) {
        if (this.cards[i].value === value) {
            index = i;
            break;
        }
    }

    if (index === undefined) {
        return;
    }

    var card = this.cards[index];
    this.cards.splice(index, 1);
    cleverapps.timeouts.setTimeout(this.reindex.bind(this), this.getReindexTimeout(1) * 1000);
    card.setOwner(Game.currentGame.table);
    this.trigger("pop", card);
    return card;
};

OpenCards.prototype.clear = function () {
    this.cards.forEach(function (card) {
        card.clear();
    });
    this.cards = [];
};

OpenCards.prototype.hide = function () {
    this.trigger("hide");
};

OpenCards.prototype.getCurrentCard = function () {
    if (this.cards.length === 0) {
        return undefined;
    }

    return this.cards[this.cards.length - 1];
};

OpenCards.OVERFLOW_ANIMATION_DURATION = 500;