/**
 * Created by mac on 5/11/24
 */

cleverapps.whenAllInitialized(function () {
    cleverapps.meta.on("afterMoveNext", function (f) {
        if (cleverapps.config.type === "tile3" && TileGenerator.getNewSkins().length) {
            cleverapps.focusManager.compound(f, [
                function (f) {
                    cleverapps.focusManager.hideAllControls();
                    cleverapps.aims.whenAllHidden(f);
                },

                function (f) {
                    new TilesUnlockWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                },

                function (f) {
                    cleverapps.focusManager.showControlsWhileFocused("progress_view");

                    f();
                }
            ]);
        } else {
            f();
        }
    });
});