/**
 * Created by slava on 4/3/20
 */

var TriPeaksScene = TileGameScene.extend({
    onSceneLoaded: function () {
        this._super();

        var tripeaks = this.game;

        var handView = this.handView = new HandView(tripeaks.hand);

        if (cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_UNDO).isAvailable()) {
            this.undoBlock = new UndoBlock(this.game.log);
        }

        this.openCardsControl = new OpenCardsView(this.game.open);
        var handBlockView = new HandBlockView(handView, this.undoBlock, this.openCardsControl);
        if (!cleverapps.gameModes.noControls) {
            this.addChild(handBlockView);
            handBlockView.setLocalZOrder(this.cardTableView.getLocalZOrder() - 1);
            handBlockView.setupChildren();

            this.handBlockView = new HidingNode(handBlockView, cleverapps.UI.VERTICAL);
            cleverapps.focusManager.registerControl("opencards", this.handBlockView);
        }

        if (tripeaks.wildcardBooster.isAvailable()) {
            var wildCardButton = new WildCardButton(tripeaks.wildcardBooster, this);
            this.addChild(wildCardButton);

            this.wildCardButton = new HidingNode(wildCardButton, cleverapps.UI.VERTICAL);
            cleverapps.focusManager.registerControl(wildCardButton.getControlId(), this.wildCardButton);
        }

        if (tripeaks.dice) {
            var diceView = new DiceView(tripeaks.dice);
            this.addChild(diceView);
        }

        if (cleverapps.resolution.mode !== cleverapps.WideMode.VERTICAL) {
            var arrows = new MultiScreenArrows(this.game.table);
            arrows.setLocalZOrder(-1);
            this.addChild(arrows);
        }
    },

    introControls: function () {
        var controls = this._super();

        if (this.game && this.game.outcome === GameBase.OUTCOME_VICTORY) {
            controls.push("opencards");
        }

        return controls;
    },

    outOfMoves: function (prolongationOffer) {
        cleverapps.focusManager.display({
            focus: "AddCardsWindow",
            action: function (f) {
                new ProlongationWindow(this.game.prolongation, {
                    offer: prolongationOffer,
                    onGiveUp: function () {
                        Game.currentGame.lose();
                    } 
                });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this)
        });
    }
});

GameScene = TriPeaksScene;
