/**
 * Created by Andrey Popov on 1/24/23.
 */

var FlagsOnCard = function (card, options) {
    this.card = card;

    this.lives = options.lives || 1;
    this.flagSuits = options.flagSuits || [];

    if (this.lives === 1) {
        this.flags = [{
            styles: cleverapps.styles.FlagsOnCardView.FLAG.center,
            suit: this.getFlagSuit(0)
        }];
    } else if (this.lives === 2) {
        this.flags = [{
            styles: cleverapps.styles.FlagsOnCardView.FLAG.left,
            suit: this.getFlagSuit(0)
        }, {
            styles: cleverapps.styles.FlagsOnCardView.FLAG.right,
            suit: this.getFlagSuit(1)
        }];
    }

    Game.currentGame.counter.registerStage(201, FlagsOnCard.process);

    this.onShow = function () {};
    this.onHide = function () {};
    this.onDestroy = function () {};

    this.onCrush = function () {};
};

FlagsOnCard.prototype.getViewClass = function () {
    return FlagsOnCardView;
};

FlagsOnCard.prototype.toJSON = function () {
    return {
        name: "flags",
        lives: this.lives,
        flagSuits: this.flagSuits
    };
};

FlagsOnCard.prototype.getFlagSuit = function (flagIndex) {
    if (this.flagSuits.length > flagIndex && this.flagSuits[flagIndex] && this.flagSuits[flagIndex] !== "unknown") {
        return this.flagSuits[flagIndex];
    }
    return cleverapps.environment.isEditorScene() ? "unknown" : Game.currentGame.generator.next().charAt(1);
};

FlagsOnCard.prototype.handleFlip = function (open) {
    if (open) {
        this.onHide();
    } else {
        this.onShow();
    }
};

FlagsOnCard.prototype.destroy = function () {
    this.onHide();

    Game.currentGame.counter.setTimeout(function () {
        this.onDestroy();
        this.card.removeComponent(FlagsOnCard);
    }.bind(this), 750);
};

FlagsOnCard.prototype.isBlocked = function () {
    return true;
};

FlagsOnCard.prototype.handleClick = function () {
    return true;
};

FlagsOnCard.prototype.magnetHit = function () {
    this.lives--;
    this.onCrush(undefined, 0, function () {
        if (this.lives < 1) {
            this.destroy();
        }
    }.bind(this));
    this.flags.splice(0, 1);
    return true;
};

FlagsOnCard.prototype.isEqualSuit = function (flag, card) {
    return flag.suit === card.getSuit()
        || (card.suits && card.suits.indexOf(flag.suit) !== -1);
};

FlagsOnCard.prototype.intercept = function (card, callback) {
    var flagIndex = -1;
    for (var i = 0; i < this.flags.length; i++) {
        if (this.isEqualSuit(this.flags[i], card)) {
            flagIndex = i;
            break;
        }
    }

    if (flagIndex === -1) {
        if (cleverapps.config.debugMode) {
            throw "interception error: cannot find flag to crush";
        }
        callback();
        return;
    }

    this.lives--;
    this.onCrush(card, flagIndex, function () {
        if (this.lives < 1) {
            this.destroy();
        }
        callback(Card.PLAY_TIMEOUT - FlagsOnCard.INTERCEPT_TIMEOUT);
    }.bind(this));
    this.flags.splice(flagIndex, 1);

    Game.currentGame.counter.setTimeout(function () {}, Card.PLAY_TIMEOUT * 1000);
};

FlagsOnCard.process = function () {
    var game = Game.currentGame;

    var openFlags = game.table.cards.filter(function (card) {
        var flagsOnCard = card.findComponent(FlagsOnCard);
        return flagsOnCard && card.isOpen() && !flagsOnCard.aboutToCrush && flagsOnCard.lives > 0;
    }).map(function (card) {
        return card.findComponent(FlagsOnCard);
    });

    if (openFlags.length === 0 || game.table.listOpen().length !== openFlags.length) {
        return;
    }

    if (game.executedMarks.some(function (mark) {
        return mark.type === "magnet";
    })) {
        return;
    }

    openFlags.forEach(function (openFlag) {
        openFlag.aboutToCrush = true;
    });

    var onSuccess = function () {
        game.counter.setTimeout(function () {
            openFlags.forEach(function (flagsOnCard) {
                flagsOnCard.flags.forEach(function (flag, flagIndex) {
                    flagsOnCard.onCrush(undefined, flagIndex, function () {
                        if (flagIndex === 0) {
                            flagsOnCard.destroy();
                        }
                    });
                });
                flagsOnCard.lives = 0;
                flagsOnCard.flags = [];
            });
        }, 100);
    };

    var onGiveUp = function () {
        game.counter.setTimeout(function () {
            game.lose();
        }, 700);
    };

    game.counter.inc();

    cleverapps.focusManager.displayWhenFreeFocus({
        focus: "RemoveFlagsWindow",
        control: ["panel_info", "opencards"],
        filter: function () {
            return Game.currentGame === game;
        },
        actions: [
            function (f) {
                game.counter.setTimeout(f, 1000);
            },

            function (f) {
                new ProlongationWindow(game.prolongation, {
                    type: Prolongation.TYPES.FLAGS,
                    onSuccess: onSuccess,
                    onGiveUp: onGiveUp
                });
                cleverapps.focusManager.onceNoWindowsListener = f;
            },

            function (f) {
                game.counter.dec();
                f();
            }
        ]
    });
};

FlagsOnCard.INTERCEPT_TIMEOUT = 0.2;
