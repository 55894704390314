/**
 * Created by olga on 23.10.2024
 */

var Meta = function () {
    cleverapps.EventEmitter.call(this);
    this.locations = {};

    this.type = this.calcType();
    this.allLocationsIds = this.calcAllLocationsIds();

    if (cleverapps.isKnockoutGame()) {
        this.knockoutGame = new KnockoutGame();
    }

    this.load();

    cleverapps.playSession.set(cleverapps.EVENTS.META_LOCATION, this.currentLocationId);
};

Meta.prototype = Object.create(cleverapps.EventEmitter.prototype);
Meta.prototype.constructor = Meta;

Meta.prototype.calcType = function () {
    if (connector.info.source === "playable") {
        return Meta.NONE;
    }

    return cleverapps.config.meta;
};

Meta.prototype.calcAllLocationsIds = function () {
    var LocationClass = this.getLocationClass();
    return LocationClass.listLocationsIds();
};

Meta.prototype.calcActiveLocationsIds = function () {
    var LocationClass = this.getLocationClass();
    return LocationClass.listActiveLocationsIds(this.currentLocationId);
};

Meta.prototype.updateLocations = function () {
    this.locations = {};
    this.activeLocationsIds = this.calcActiveLocationsIds();

    this.activeLocationsIds.forEach(function (locationId) {
        var LocationClass = this.getLocationClass();
        if (!this.locations[locationId]) {
            this.locations[locationId] = new LocationClass(locationId);
        }
    }.bind(this));

    this.switchLocation(this.currentLocationId);
};

Meta.prototype.switchLocation = function (locationId) {
    if (this.activeLocationsIds.indexOf(locationId) === -1) {
        locationId = this.getLastLocationId();
    }

    this.selectedLocationId = locationId;
};

Meta.prototype.getType = function () {
    return this.type;
};

Meta.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.META);
    data = this.migrateData(data);
    this.updateData(data);

    if (this.needSave) {
        this.save();
    }
};

Meta.prototype.migrateData = function (data) {
    var type = this.getType();
    if (type === Meta.FARM) {
        data = cleverapps.dataLoader.load(DataLoader.TYPES.FARM);
    } else if (type === Meta.HOMEFIX) {
        if (!data) {
            data = cleverapps.dataLoader.load(DataLoader.TYPES.HOMEFIX) || {};
        }
    } else if (type === Meta.SHORTMETA) {
        data = cleverapps.dataLoader.load(DataLoader.TYPES.SHORTMETA)
            || cleverapps.dataLoader.load(DataLoader.TYPES.KNOCKOUT);
    } else if (type === Meta.SIMPLE) {
        if (Version.compare(cleverapps.user.lastVersion, Meta.SIMPLE_MIGRATE_VERSION) < 0) {
            data = cleverapps.dataLoader.load(DataLoader.TYPES.SIMPLE);
            this.needSave = true;
        }
    } else if (type === Meta.HOSE) {
        data = cleverapps.dataLoader.load(DataLoader.TYPES.HOSE);
    }
    return data;
};

Meta.prototype.updateData = function (data) {
    data = data || {};
    if (cleverapps.config.rpg && !data && levels.user.episode > 0) {
        data = {
            stars: 0,
            current: levels.user.episode
        };
    }

    this.currentLocationId = data.current || 0;
    this.stars = data.stars || 0;

    if (this.knockoutGame) {
        var knockoutLevel;

        if (data.knockoutLevel !== undefined) {
            knockoutLevel = data.knockoutLevel;
        } else if (data.cups !== undefined) {
            knockoutLevel = data.cups;
        } else {
            knockoutLevel = cleverapps.user.getVirtualProgress();
        }

        this.knockoutGame.setLevel(knockoutLevel);
    }
};

Meta.prototype.updateInfo = function (serverData) {
    this.locations = {};
    this.updateData(serverData);
    this.updateLocations();
    this.save(true);

    if (cleverapps.highscore) {
        cleverapps.highscore.updateInfo(serverData);
        cleverapps.highscore.save(true);
    }
};

Meta.prototype.getData = function () {
    var data = {
        current: this.currentLocationId,
        stars: this.stars
    };

    if (this.knockoutGame) {
        data.knockoutLevel = this.knockoutGame.getLevel();
    }

    return data;
};

Meta.prototype.getInfo = function () {
    var data = this.getData();

    if (cleverapps.highscore) {
        Object.assign(data, cleverapps.highscore.getInfo());
    }

    return data;
};

Meta.prototype.save = function (fromSever) {
    cleverapps.dataLoader.save(DataLoader.TYPES.META, this.getData());
    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

Meta.prototype.loadLocation = function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.LOCATION + slot) || {};
};

Meta.prototype.saveLocation = function (location, fromSever) {
    var slot = location.getSlotId();

    cleverapps.dataLoader.save(DataLoader.TYPES.LOCATION + slot, location.getInfo());

    if (!fromSever) {
        // cleverapps.synchronizer.addUpdateTask("location" + slot);
    }
};

Meta.prototype.listAllLocationIds = function () {
    return this.allLocationsIds;
};

Meta.prototype.getMainObject = function () {
    return this.locations[this.selectedLocationId];
};

Meta.prototype.gamePlayed = function (outcome, game) {
    if (outcome === GameBase.OUTCOME_VICTORY) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
        cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);
    }

    this.getMainObject().gamePlayed(outcome, game);
};

Meta.prototype.resetStars = function () {
    this.stars = 0;
    this.save();
};

Meta.prototype.changeStars = function (stars, silent) {
    this.stars += stars;
    this.save();
    if (!silent) {
        this.onChangeStars(stars);
    }
};

Meta.prototype.onChangeStars = function (stars) {
    this.trigger("changeStars", stars);
};

Meta.prototype.getNextLocationId = function () {
    return this.currentLocationId + 1;
};

Meta.prototype.isCompleted = function () {
    return this.currentLocationId > this.getLastLocationId();
};

Meta.prototype.getLastLocationId = function () {
    return this.allLocationsIds.length - 1;
};

Meta.prototype.moveNextLocation = function (f) {
    this.currentLocationId++;
    this.save();

    if (this.isCompleted()) {
        new AllDoneWindow();
        cleverapps.focusManager.onceNoWindowsListener = f;
    } else {
        this.updateLocations();
        this.getMainObject().load({});
        this.getMainObject().save();
        this.getMainObject().onOpen(f);
    }
};

Meta.prototype.afterMoveNext = function (f) {
    this.trigger("afterMoveNext", f);
};

Meta.prototype.getRumble = function () {
    return this.knockoutGame && this.knockoutGame.rumble;
};

Meta.prototype.stopRumble = function () {
    return this.knockoutGame.stopRumble();
};

Meta.prototype.wantsToPlay = function (f, level) {
    if (cleverapps.lives && cleverapps.lives.isEmpty()) {
        if (cleverapps.unlimitedLives && cleverapps.unlimitedLives.freeOfferIsAvailable()) {
            cleverapps.unlimitedLives.showFreeOffer(f);
        } else {
            new LivesShopWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }

        return;
    }

    var mainObject = this.getMainObject();

    if (this.getType() === Meta.FARM) {
        var building = level && level.building && level.building.canStillProvideQuestItems()
            ? level.building
            : mainObject.findBuildingForActiveItem() || cleverapps.Random.mathChoose(mainObject.listAvailableProvidesBuildings());

        level = MethaHelper.getCurrentLevel(building);
    } else {
        level = level || MethaHelper.getCurrentLevel();
    }

    if (this.getType() === Meta.HOSE) {
        cleverapps.Plot.isPassedAllEpisodes = levels.user.isPassedAll();
    }

    if (this.knockoutGame) {
        this.knockoutGame.wantsToPlay(f, level);
    } else {
        MethaHelper.start(f, level);
    }
};

Meta.prototype.wantsToReplay = function (f, level) {
    MethaHelper.start(f, level);
};

Meta.prototype.reset = function () {
    this.currentLocationId = 0;
    this.stars = 0;
    this.updateLocations();

    if (this.knockoutGame) {
        this.knockoutGame.reset();
    }
    this.save();
};

Meta.prototype.getLocationClass = function () {
    if (connector.info.source === "playable") {
        return PlayableAdsScene;
    }

    switch (this.getType()) {
        case Meta.FARM:
            return Farm;

        case Meta.HOMEFIX:
            return Home;

        case Meta.FISHDOM:
            return Fishdom;

        case Meta.SHORTMETA:
            return ShortMeta;

        case Meta.SIMPLE:
            return Simple;

        case Meta.NONE:
            return NoMetha;

        default:
            return Hose;
    }
};

Meta.prototype.getMainScene = function () {
    if (connector.info.source === "playable") {
        return PlayableAdsScene;
    }

    switch (this.getType()) {
        case Meta.FARM:
            return FarmScene;

        case Meta.HOMEFIX:
            return HomefixScene;

        case Meta.FISHDOM:
            return FishdomScene;

        case Meta.SHORTMETA:
            return ShortMetaScene;

        case Meta.SIMPLE:
            return SimpleMainScene;

        case Meta.NONE:
            return GameScene;

        default:
            return HoseScene;
    }
};

cleverapps.whenAllInitialized(function () {
    Snapshots.registerBySlots("locations", function (slot) {
        return cleverapps.dataLoader.load(DataLoader.TYPES.LOCATION + slot) || {};
    }, function (slot, serverData) {
        cleverapps.dataLoader.save(DataLoader.TYPES.LOCATION + slot, serverData);
    });
});

cleverapps.whenAllInitialized(function () {
    Snapshots.registerBySlots("locations", function (slot) {
        return cleverapps.dataLoader.load(DataLoader.TYPES.LOCATION + slot) || {};
    }, function (slot, serverData) {
        cleverapps.dataLoader.save(DataLoader.TYPES.LOCATION + slot, serverData);
    });
});

// CustomSyncers.registerBySlots("locations", function (slot) {
//     return cleverapps.dataLoader.load(DataLoader.TYPES.LOCATION + slot) || {};
// }, function (slot, serverData) {
//     cleverapps.dataLoader.save(DataLoader.TYPES.LOCATION + slot, serverData);
// });

Meta.HOSE = "hose";
Meta.FARM = "farm";
Meta.HOMEFIX = "homefix";
Meta.SHORTMETA = "shortmeta";
Meta.SIMPLE = "simple";
Meta.NONE = "none";
Meta.FISHDOM = "fishdom";

Meta.SIMPLE_MIGRATE_VERSION = "1.408.1";

Meta.SLOT_MAIN = "";
Meta.SLOTS = [Meta.SLOT_MAIN];

if (cleverapps.config.type === "merge") {
    Meta.EXPEDITION_SLOT1 = "1";
    Meta.EXPEDITION_SLOT2 = "2";
    Meta.EXPEDITION_SLOT3 = "3";
    Meta.EXPEDITION_SLOT4 = "4";

    Meta.SLOTS = [Meta.SLOT_MAIN, Meta.EXPEDITION_SLOT1, Meta.EXPEDITION_SLOT2, Meta.EXPEDITION_SLOT3, Meta.EXPEDITION_SLOT4];
}

if (cleverapps.config.meta === "homefix") {
    Meta.LOCATION_SLOT0 = "0";
    Meta.LOCATION_SLOT1 = "1";
    Meta.LOCATION_SLOT2 = "2";

    Meta.SLOTS = [Meta.SLOT_MAIN, Meta.LOCATION_SLOT0, Meta.LOCATION_SLOT1, Meta.LOCATION_SLOT2];
}
if (cleverapps.config.debugMode) { // only before update
    Meta.SIMPLE_MIGRATE_VERSION = "1.405.33";
}