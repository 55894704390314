/**
 * Created by Andrey Popov on 1/25/23.
 */

var ChainOnCardView = cc.Node.extend({
    ctor: function (chainOnCard) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.chainAnimation = new cleverapps.Spine(bundles.card.jsons.chains_json);
        this.chainAnimation.setAnimation(0, "chain_idle", true);
        this.chainAnimation.setPosition(cleverapps.styles.CardView.width / 2, cleverapps.styles.CardView.height / 2);
        this.chainAnimation.visible = cleverapps.environment.isSceneWithPreview();
        this.addChild(this.chainAnimation);

        this.lockAnimation = new cleverapps.Spine(bundles.card.jsons.lock_json);
        this.lockAnimation.setAnimation(0, "lock_idle", true);
        this.lockAnimation.setPosition(cleverapps.styles.CardView.width / 2, cleverapps.styles.CardView.height / 2);
        this.lockAnimation.visible = cleverapps.environment.isSceneWithPreview();
        this.addChild(this.lockAnimation);

        chainOnCard.onShowUp = this.createListener(this.showUp);
        chainOnCard.onHide = this.createListener(this.hide);
        chainOnCard.onDestroy = this.createListener(this.onDestroy);
    },

    showUp: function () {
        var appearance = new cleverapps.Spine(bundles.card.jsons.effects_json);
        appearance.setAnimation(0, "appearance", false);
        appearance.setPosition(cleverapps.styles.CardView.width / 2, cleverapps.styles.CardView.height / 2);
        appearance.setCompleteListenerRemove(function () {
            this.show();
        }.bind(this));
        this.addChild(appearance);

        cleverapps.audio.playSound(bundles.game.urls.decorator_appear_effect);

        return ChainOnCardView.SHOWUP_DELAY;
    },

    onDestroy: function () {
        this.lockAnimation.setAnimation(0, "lock_open_key", false);

        this.chainAnimation.setAnimation(0, "chain_open", false);
        this.chainAnimation.setCompleteListener(function () {
            this.removeFromParent();
        }.bind(this));
    },

    show: function () {
        this.lockAnimation.visible = true;
        this.chainAnimation.visible = true;

        this.lockAnimation.setRotation(-this.parent.rotation);
    },

    hide: function () {
        this.lockAnimation.visible = false;
        this.chainAnimation.visible = false;
    }
});

ChainOnCardView.SHOWUP_DELAY = 500;