cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_COMBO] = {
    model: ComboBooster,
    type: cleverapps.Boosters.TYPES.CHEAP,
    limit: AdsLimits.TYPES.BOOSTERS_BEFORE,
    icon: bundles.reward_icons.frames.heroes_png,
    force: Forces.FIRST_BOOSTERS_BEFORE,
    available: {
        level: 1.73
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_MOVES] = {
    model: MovesBooster,
    type: cleverapps.Boosters.TYPES.MEDIUM,
    limit: AdsLimits.TYPES.BOOSTERS_BEFORE,
    icon: bundles.reward_icons.frames.moves_png,
    force: Forces.SECOND_BOOSTERS_BEFORE,
    available: {
        level: 2.4
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_MULTICOLOR] = {
    model: MultiColorBooster,
    type: cleverapps.Boosters.TYPES.EXPENSIVE,
    limit: AdsLimits.TYPES.BOOSTERS_BEFORE,
    icon: bundles.reward_icons.frames.multicolor_png,
    force: Forces.THIRD_BOOSTERS_BEFORE,
    available: {
        level: 2.93
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_MAGNET] = {
    model: MagnetBooster,
    type: cleverapps.Boosters.TYPES.CHEAP,
    limit: AdsLimits.TYPES.BOOSTERS_BEFORE,
    icon: bundles.reward_icons.frames.magnet_png,
    force: Forces.FIRST_BOOSTERS_BEFORE,
    available: {
        level: 1.85
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_DICE] = {
    model: DiceBooster,
    type: cleverapps.Boosters.TYPES.MEDIUM,
    limit: AdsLimits.TYPES.BOOSTERS_BEFORE,
    icon: bundles.reward_icons.frames.dice_png,
    force: Forces.SECOND_BOOSTERS_BEFORE,
    available: {
        level: 2.4
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_JOKERS] = {
    model: TwoJokersBooster,
    type: cleverapps.Boosters.TYPES.EXPENSIVE,
    limit: AdsLimits.TYPES.BOOSTERS_BEFORE,
    icon: bundles.reward_icons.frames.joker_png,
    force: Forces.THIRD_BOOSTERS_BEFORE,
    available: {
        level: 2.93
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_HIGHLIGHT] = {
    model: HighlightBooster,
    type: cleverapps.Boosters.TYPES.CHEAP,
    limit: AdsLimits.TYPES.BOOSTERS_BEFORE,
    icon: bundles.reward_icons.frames.highlight,
    force: Forces.FIRST_BOOSTERS_BEFORE,
    available: {
        level: 1.33
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_EYE] = {
    model: EyeBooster,
    type: cleverapps.Boosters.TYPES.MEDIUM,
    limit: AdsLimits.TYPES.BOOSTERS_BEFORE,
    icon: bundles.reward_icons.frames.eye,
    force: Forces.SECOND_BOOSTERS_BEFORE,
    available: {
        level: 2.4
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_MAGNIFIER] = {
    model: MagnifierBooster,
    type: cleverapps.Boosters.TYPES.EXPENSIVE,
    limit: AdsLimits.TYPES.BOOSTERS_BEFORE,
    icon: bundles.reward_icons.frames.hint,
    force: Forces.THIRD_BOOSTERS_BEFORE,
    available: {
        level: 2.93
    }
};