TournamentPlayerView.prototype.createName = function () {
};

TournamentPlayerView.prototype.addClickProcessing = function () {
    cleverapps.UI.onClick(this, function () {
        if (cleverapps.config.debugMode) {
            Game.currentGame && Game.currentGame.discoverDebug(this.player);
        }

        cleverapps.tooltipManager.onClick(this, {
            text: this.player.name,
            size: connector.info.isMobile ? cleverapps.styles.CookieJarView.mobileTooltipSize : cleverapps.styles.CookieJarView.tooltipSize,
            location: cleverapps.UI.Tooltip.LOCATION_ABOVE,
            forceShow: true
        });
    }.bind(this));
};

TournamentPlayerView.prototype.processPlaceAnimation = function () {

};

cleverapps.overrideStyles(cleverapps.styles.TournamentPlayerView, {
    avatar: {
        scale: 0.9
    },
    amount: {
        x: { align: "right", dx: 0 },
        y: { align: "bottom", dy: -3 },
        scale: 0.9,
        text: {
            x: { align: "center" },
            y: { align: "center", dy: 3 }
        }
    }
});