/**
 * Created by Andrey Popov on 22.04.20
 */

var Prolongation = function () {
    cleverapps.EventEmitter.call(this);
    this.counter = 0;

    this.onCompleteOffer = function () {};
};

Prolongation.prototype = Object.create(cleverapps.EventEmitter.prototype);
Prolongation.prototype.constructor = Prolongation;

Prolongation.prototype.getOfferCurrencyPrice = function () {
    var price = this.isCustomWhaleFirstStep() ? 250 : Prolongation.PRICES[this.counter];
    return cleverapps.config.soft ? Math.floor(price / 2) : price;
};

Prolongation.prototype.checkEligibleFree = function (type, noFree) {
    return (
        (
            (levels.user.getFloatLevel() < 1.4 && !cleverapps.forces.isShown(Forces.FREE_PROLONGATION_FORCE.id))
            || (type === Prolongation.TYPES.SLOT && !cleverapps.forces.isShown(Forces.FREE_EXTRA_SLOT_FORCE.id))
        )
        && !noFree);
};

Prolongation.prototype.getOffer = function (type, noFree) {
    var moves;
    if (type === Prolongation.TYPES.MOVES) {
        moves = Game.currentGame.getProlongationMovies();
    }
    if (this.checkEligibleFree(type, noFree)) {
        return {
            type: type,
            priceType: "free",
            price: 0,
            moves: moves,
            text: "FREE",
            force: (
                type === Prolongation.TYPES.SLOT
                    ? Forces.FREE_EXTRA_SLOT_FORCE
                    : Forces.FREE_PROLONGATION_FORCE
            )
        };
    }

    if (this.isAdAvailable()) {
        return {
            type: type,
            priceType: "ads",
            moves: moves,
            text: "##"
        };
    }

    var price = this.getOfferCurrencyPrice();

    if (type === Prolongation.TYPES.MOVES && this.isCustomWhaleFirstStep()) {
        moves *= 2;
    }
    return {
        type: type,
        priceType: "currency",
        price: price,
        moves: moves,
        text: "$$" + price
    };
};

Prolongation.prototype.acceptOffer = function (offer) {
    switch (offer.priceType) {
        case "free":
            this.onAcceptOffer(offer);

            break;

        case "ads":
            cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.PROLONGATION, function () {
                this.onAcceptOffer(offer);
                cleverapps.adsLimits.watch(AdsLimits.TYPES.PROLONGATION);
            }.bind(this), function () {
                this.trigger("adsCanceled", offer);
            }.bind(this));

            break;

        case "currency":
            var spend = levels.user.spendHard.bind(levels.user);

            if (spend(cleverapps.EVENTS.SPENT.CONTINUE, offer.price)) {
                this.onAcceptOffer(offer);
                this.escalateToUpperLevel();

                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.HINT_USED, { value: offer.price });
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MOVES_BOUGHT + "_" + offer.price);
            }
            break;
    }
};

Prolongation.prototype.isAdAvailable = function () {
    return cleverapps.adsLimits.state(AdsLimits.TYPES.PROLONGATION) === AdsLimits.STATE_READY
        && cleverapps.rewardedAdsManager.isRewardedAvailable();
};

Prolongation.prototype.isCustomWhaleFirstStep = function () {
    return this.counter === 0 && (levels.user.gold > 500
        || cleverapps.paymentsHistory.classify() === cleverapps.PaymentsHistory.BRACKET_WHALE
        || cleverapps.paymentsHistory.classify() === cleverapps.PaymentsHistory.BRACKET_BIGWHALE);
};

Prolongation.prototype.escalateToUpperLevel = function () {
    this.counter++;

    if (this.counter >= Prolongation.PRICES.length && !cleverapps.highscore) {
        this.counter = Prolongation.PRICES.length - 1;
    }
};

Prolongation.prototype.getAlertMessage = function (prolongationType) {
    if (prolongationType === Prolongation.TYPES.OCTOPUS_HUNT) {
        var octopusMission = cleverapps.missionManager.findRunningMission(Mission.TYPE_OCTOPUS_HUNT);
        var octopusHunt = octopusMission.logic.octopusHunt;

        return Messages.get("OctopusHuntProlongationWindow.leftStages", {
            stages: octopusHunt.calcStagesBeforeBonus()
        });
    }

    var game = Game.currentGame;
    var mission = game.secondaryMission;

    var lantern = Lantern.Get();
    var percent = game.getPercentOfCompletion();
    var goldCollected = game.rewards[GameBase.REWARD_HARD];

    var type = undefined;
    if (game.rewards[GameBase.REWARD_BOOSTERS]) {
        type = Prolongation.GIVE_UP_TYPES.BOOSTER;
    } else if (lantern && lantern.isActive(game.level) && lantern.getCurrentStreak() > 0) {
        type = Prolongation.GIVE_UP_TYPES.LANTERN;
    } else if (mission && mission.isRunning() && game.rewards.clover > 0) {
        type = Prolongation.GIVE_UP_TYPES.MISSION;
    } else if (goldCollected && goldCollected > 0) {
        type = Prolongation.GIVE_UP_TYPES.GOLD_COLLECTED;
    } else if (percent >= 0.70) {
        type = Prolongation.GIVE_UP_TYPES.NEARLY;
    }

    if (type === undefined) {
        return;
    }

    var message = [Messages.get("GiveUpAlert.text1")];

    if (type === Prolongation.GIVE_UP_TYPES.NEARLY) {
        message.push(Messages.get("AddMovesWindow.nearlyDoneGiveUp"));
    }

    if (cleverapps.ListBreaks().length > 0) {
        message.push(Messages.get("GiveUpAlert.text2"));
    }

    return message.join("\n");
};

Prolongation.prototype.getText = function (type) {
    if (type === Prolongation.TYPES.BOMB) {
        return Messages.get("PostponeBombWindow.addMoves", {
            moves: BombOnCard.MOVES_TO_ADD
        });
    }

    if (type === Prolongation.TYPES.FLAGS) {
        return Messages.get("RemoveFlagsWindow.text");
    }

    if (type === Prolongation.TYPES.OCTOPUS_HUNT) {
        return Messages.get("OctopusHuntProlongationWindow.Text");
    }

    return Messages.get("ProlongationWindow.addMoves");
};

Prolongation.prototype.hasOffer = function () {
    return Prolongation.PRICES[this.counter] !== undefined;
};

Prolongation.prototype.onAcceptOffer = function (offer) {
    this.trigger("acceptOffer", offer);
};

Prolongation.GIVE_UP_TYPES = {
    BOOSTER: 0,
    LANTERN: 1,
    MISSION: 2,
    GOLD_COLLECTED: 3,
    NEARLY: 4
};

Prolongation.PRICES = [
    100,
    300,
    500,
    700,
    1000
];

Prolongation.TYPES = {};
Prolongation.TYPES.MOVES = 1;
Prolongation.TYPES.BOMB = 2;
Prolongation.TYPES.FLAGS = 3;
Prolongation.TYPES.SLOT = 4;
Prolongation.TYPES.OCTOPUS_HUNT = 5;