/**
 * Created by mac on 2/25/20
 */

var Hand = function (game, options) {
    cleverapps.EventEmitter.call(this);

    this.game = game;
    this.difficulty = options.difficulty || 3;
    this.extra = new ExtraHand(game, this);

    this.nullDraws = 0;

    if (options.stack) {
        this.loadSave(options.stack);
    } else {
        this.stack = [];

        for (var i = 0; i < options.num; i++) {
            this.push(TileFactory.Create({}, { noMarks: true }));
        }
    }

    this.customCards = options.cards ? cleverapps.clone(options.cards) : [];
    this.extra.update();

    this.getView = function () {};
};

Hand.prototype = Object.create(cleverapps.EventEmitter.prototype);
Hand.prototype.constructor = Hand;

Hand.prototype.getCardPosition = function (card) {
    var position = {};

    var view = this.getView();
    if (!view) {
        return position;
    }

    var index = this.stack.indexOf(card);
    if (index === -1) {
        return position;
    }

    position.parent = view;
    position.point = view.calcPosition(index);
    return position;
};

Hand.prototype.loadSave = function (data) {
    this.stack = data.map(function (item) {
        var card = TileFactory.Create(item, { noMark: true });
        card.setOwner(this);
        card.registerAnimationTime(Card.PLAY_TIMEOUT);
        return card;
    }.bind(this));
};

Hand.prototype.getInfo = function () {
    return {
        difficulty: this.difficulty,
        stack: this.stack.map(function (card) {
            return card.toJSON();
        })
    };
};

Hand.prototype.cardClicked = function () {
    if (!this.isEmpty()) {
        if (!this.top().inAnimation()) {
            Game.currentGame.shiftCard();
            return true;
        }
    }
    return false;
};

Hand.prototype.isEmpty = function () {
    return this.stack.length === 0;
};

Hand.prototype.addExtra = function (count, options) {
    this.game.counter.inc();

    var cards = [];

    var suitableCard = new Card();
    this.setSuitableValue(Game.currentGame.table, suitableCard);

    for (var i = 0; i < count - 1; i++) {
        cards.push(new Card());
    }

    var bonus = options.bonus || 0;
    for (i = 0; i < bonus; i++) {
        cards.push(new Wildcard());
    }

    cards = cleverapps.Random.shuffle(cards);

    var suitableCardIndex = Math.random() < 0.5 ? cards.length : cards.length - 1;
    cards.splice(suitableCardIndex, 0, suitableCard);

    for (i = 0; i < cards.length; i++) {
        this.push(cards[i]);
    }

    this.showUp(function () {
        this.game.counter.dec();
    }.bind(this));
};

Hand.prototype.push = function (card, options) {
    options = options || {};

    if (card === undefined) {
        card = new Card();
    }

    var index = options.insertIndex !== undefined ? options.insertIndex : this.stack.length;
    if (card instanceof Wildcard && Hand.JOKER_POSITION_IN_HAND !== undefined) {
        index = this.stack.length - Hand.JOKER_POSITION_IN_HAND;
    }
    this.stack.splice(index, 0, card);

    card.setOwner(this);
    card.registerAnimationTime(Card.PLAY_TIMEOUT);
    this.trigger("insert", card, {
        index: index,
        fromTable: options.fromTable,
        leftward: options.insertIndex === 0
    });

    this.game.counter.trigger();

    this.extra.update();
};

Hand.prototype.moveExist = function (table, proposedShiftCard) {
    if (!proposedShiftCard) {
        return false;
    }

    for (var i = 0; i < table.cards.length; i++) {
        var card = table.cards[i];
        if (card.canPlay(proposedShiftCard)) {
            return true;
        }
    }

    return false;
};

Hand.prototype.setSuitableValue = function (table, card) {
    var n = 0;
    while (n < 1000) {
        if (this.moveExist(table, card)) {
            return true;
        }
        n++;
        card.setRandomValue();
    }
    return false;
};

Hand.prototype.pop = function () {
    var card = this.stack.pop();
    this.trigger("pop");
    return card;
};

Hand.prototype.shift = function (table) {
    var card = this.pop();
    var updated = false;

    var update = function () {
        if (Game.currentGame.cardsTutorial) {
            var handValue = Game.currentGame.cardsTutorial.getHandValue();
            if (handValue !== undefined) {
                updated = true;

                card.setRank(handValue.charAt(0));
                if (handValue.charAt(1)) {
                    card.setSuit(handValue.charAt(1));
                }
            }
        } else if (this.customCards.length > 0) {
            var value = this.customCards.shift();

            card.setSuit(value.charAt(1));
            card.setRank(value.charAt(0));
            updated = true;
        } else if (!updated) {
            while (true) {
                var currentCard = Game.currentGame.open.getCurrentCard();
                if (!currentCard || currentCard instanceof Wildcard || card.getRank() !== currentCard.getRank()) {
                    break;
                }
                updated = true;
                card.setRandomValue();
            }
        }
        if (this.nullDraws > 0 && this.nullDraws >= this.difficulty - 1
            && cleverapps.Random.nextDouble() > 0.5 - (this.nullDraws - this.difficulty + 1) * 0.15) {
            updated = this.setSuitableValue(table, card);
        }
    }.bind(this);

    if (!card.wasShown) {
        update();
    }

    if (this.moveExist(table, card)) {
        this.nullDraws = 0;
    } else {
        this.nullDraws++;
    }

    card.open();
    card.trigger("changeValue");

    this.extra.update();

    return card;
};

Hand.prototype.top = function () {
    if (this.stack.length === 0) {
        return undefined;
    }
    return this.stack[this.stack.length - 1];
};

Hand.prototype.showUp = function (f, silent) {
    if (!this.stack.length) {
        f();
        return;
    }

    f = cleverapps.wait(this.stack.length, f);

    this.stack.forEach(function (card, index) {
        card.hide();
        var delay = 0.1 * index;
        card.registerAnimationTime(delay + Card.SHOW_UP_TIMEOUT);
        card.trigger("handShowUp", f, delay, silent);
    });
};

Hand.prototype.showExtraTutorialStep = function () {
    return this.extra.showTutorialStep();
};

Hand.prototype.clear = function () {
    this.stack.forEach(function (card) {
        card.clear();
    });
    this.stack = [];
};

Hand.prototype.animateBonus = function (f) {
    for (var i = 0; i < this.stack.length; i++) {
        if (this.stack[i].inAnimation()) {
            cleverapps.timeouts.setTimeout(function () {
                this.animateBonus(f);
            }.bind(this), 100);
            return;
        }
    }

    var addReward = function (order) {
        var reward = Hand.ONE_LEFT_CARD_REWARD;
        if (order >= 5) {
            reward = Hand.ONE_LEFT_CARD_REWARD_MORE_FIVE;
        }
        if (order >= 10) {
            reward = Hand.ONE_LEFT_CARD_REWARD_MORE_TEN;
        }
        this.game.addHardReward(reward, true);
    }.bind(this);

    var actions = [];
    var duration = Card.ANIMATE_LEFT_DURATION;

    var needWaitFinish = false;

    for (i = 0; i < this.stack.length; i++) {
        var card = this.stack[this.stack.length - 1 - i];

        actions.push(function (card, order, f) {
            if (Game.currentGame.skipBonusAnimations) {
                addReward(order);
                f();
                return;
            }

            needWaitFinish = true;

            card.trigger("animateLeft", duration, addReward.bind(null, order));

            cleverapps.timeouts.setTimeout(f, duration * 1000 / 6);
        }.bind(this, card, i));
    }

    actions.push(function (f) {
        cleverapps.timeouts.setTimeout(f, needWaitFinish ? duration * 1000 : 0);
    });

    cleverapps.focusManager.hideControlsWhileFocused("comboBar");

    cleverapps.focusManager.compound(f, actions);
};

Hand.prototype.hideExtra = function () {
    return this.extra.hide();
};

Hand.ONE_LEFT_CARD_REWARD = 5;
Hand.ONE_LEFT_CARD_REWARD_MORE_FIVE = 3;
Hand.ONE_LEFT_CARD_REWARD_MORE_TEN = 1;

Hand.JOKER_POSITION_IN_HAND = undefined;