/**
 * Created by Andrey Popov on 12.05.20
 */

TilesTutorial.TUTORIAL_LEVELS = {
    initial: {
        handValues: ["2h"],
        tutorial: [{
            force: "InitialTutorialMessage1"
        }]
    },
    hand: {
        handValues: ["2s", "6", "2"],
        tutorial: cleverapps.arrayFill(3, { action: "highlight" }).concat([{
            force: "HandTutorialMessage",
            amountExtraCards: 8
        }])
    },
    bonus: {
        handValues: ["k", "6", "8"],
        tutorial: [{
            force: "BonusTutorialMessage",
            filter: ["4"]
        }].concat(cleverapps.arrayFill(2, { action: "highlight" }))
    },
    wildCard: {
        handValues: ["8"],
        tutorial: [{
            force: "WildCardTutorialMessage",
            type: TilesTutorial.TYPE_WILDCARD
        }, {
            force: ""
        }]
    },
    jackpot: {
        handValues: ["j"],
        tutorial: [{
            type: TilesTutorial.TYPE_JACKPOT,
            force: "JackpotTutorialMessage",
            filter: ["t"]
        }]
    },
    ice: {
        importantCards: [IceOnCard],
        handValues: ["9"],
        tutorial: [{
            filter: [IceOnCard],
            force: "IceTutorialMessage"
        }]
    },
    incDecValue: {
        handValues: ["2"],
        tutorial: [{
            force: "IncDecValueTutorialMessage"
        }]
    },
    keyLock: {
        handValues: ["q"],
        tutorial: [
            {
                force: "KeyLockTutorialMessage"
            }
        ]
    },
    dogStreak: {
        handValues: ["5"],
        importantCards: [StreakCard],
        tutorial: [{
            force: "DogStreakTutorialMessage",
            filter: ["4", StreakCard]
        }]
    },
    shark: {
        handValues: ["2"],
        importantCards: [SharkCard],
        tutorial: [{
            force: "SharkTutorialMessage",
            filter: ["3", SharkCard]
        }]
    },
    bomb: {
        handValues: ["9"],
        tutorial: [{
            force: "BombTutorialMessage",
            filter: []
        }]
    },
    trump: {
        handValues: ["4"],
        tutorial: [{ force: "TrumpTutorialMessage" }]
    },
    magnet: {
        handValues: ["3"],
        tutorial: [{
            force: "MagnetTutorialMessage"
        }]
    },
    double: {
        handValues: ["a"],
        tutorial: [{
            force: "DoubleTutorialMessage"
        }]
    },
    mouseAndCheese: {
        handValues: ["8"],
        tutorial: [{
            force: "MouseAndCheeseTutorialMessage",
            filter: [MouseCard, CheeseCard]
        }]
    },
    suitCard: {
        handValues: ["9h"],
        tutorial: [{
            force: "SuitCard1TutorialMessage",
            filter: [SuitCard]
        }, {
            force: "SuitCard2TutorialMessage",
            filter: ["2"]
        }]
    },
    flag: {
        handValues: ["2"],
        importantCards: [FlagsOnCard],
        tutorial: [{
            force: "Flag1TutorialMessage",
            filter: ["a", FlagsOnCard]
        }, {
            force: "Flag2TutorialMessage",
            filter: ["k", FlagsOnCard]
        }]
    },
    curtain: {
        handValues: ["4"],
        tutorial: [{
            force: "CurtainTutorialMessage1",
            filter: [CurtainCard]
        }, {
            force: "CurtainTutorialMessage2",
            filter: [CurtainCard]
        }]
    },
    safe: {
        handValues: ["8h"],
        importantCards: [SafeCard],
        tutorial: [{
            force: "SafeTutorialMessage1",
            filter: [SafeCard]
        }, {
            force: "SafeTutorialMessage2",
            filter: [SafeCard]
        }, {
            force: "SafeTutorialMessage3",
            filter: [SafeCard]
        }]
    }
};
