/**
 * Created by slava on 23/11/17
 */

var MiniGameWindow = CleverappsWindow.extend({
    onWindowLoaded: function (reward) {
        this.reward = reward;

        var styles = cleverapps.styles.MiniGameWindow;

        this.text = cleverapps.UI.generateOnlyText("MiniGameWindow.Text", cleverapps.styles.FONTS.WINDOW_TEXT);
        this.text.fitTo(styles.text.width);

        var boxes = this.createBoxesNode();

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles.size);

        content.addChild(boxes);
        boxes.setPositionRound(styles.animation);

        content.addChild(this.text);
        this.text.setPositionRound(styles.text);

        this._super({
            title: "MiniGameWindow.Title",
            name: "minigamewindow",
            content: content,
            closeButton: false
        });
    },

    createBoxesNode: function () {
        var styles = cleverapps.styles.MiniGameWindow;

        var node = new cc.Node();
        node.setAnchorPoint(0.5, 0.5);

        this.boxes = [];
        styles.boxes.forEach(function (position, index) {
            var box = this.createBox(index);

            this.boxes.push(box);

            node.addChild(box);
            box.setPositionRound(position);
        }, this);

        cleverapps.UI.wrap(node);

        return node;
    },

    createBox: function (index) {
        var styles = cleverapps.styles.MiniGameWindow;

        var animation = new cleverapps.Spine(bundles.minigame.jsons.minigame_json);
        animation.setSkin(["1", "2", "3"][index]);
        animation.setAnimation(0, "idle", true);
        animation.setPositionRound(styles.box);

        cleverapps.UI.applyHover(animation);
        cleverapps.UI.onClick(animation, this.onPressed.bind(this));

        return animation;
    },

    onShow: function () {
        this._super();
        this.showUpAnimation();
    },

    onHide: function () {
        this._super();

        if (this.boxes) {
            this.boxes.forEach(function (box) {
                box.stopAllActions();
            });
        }

        if (this.text) {
            this.text.stopAllActions();
        }
    },

    showUpAnimation: function () {
        var boxes = this.boxes;

        var positions = boxes.map(function (box) {
            return box.getPosition();
        });

        var targets = [[2, 0, 1], [0, 2, 1], [1, 0, 2]];
        this.text.setOpacity(0);
        var rotations = [[20, -20, 20, -20], [-20, 20, -20, 20], [20, 0, -20, 0]];

        cleverapps.focusManager.display({
            stack: true,
            focus: "BoxesAnimation",
            action: function (f) {
                boxes.forEach(function (box, id) {
                    box.runAction(new cc.Sequence(
                        new cc.DelayTime(0.5),
                        new cc.Spawn(
                            new cc.MoveTo(0.3, positions[targets[0][id]]),
                            new cc.Sequence(
                                new cc.RotateBy(0.2, rotations[id][0]),
                                new cc.RotateBy(0.1, -rotations[id][0])
                            )
                        ),
                        new cc.DelayTime(0.1),
                        new cc.CallFunc(function () {
                            if (id === 0) {
                                cleverapps.audio.playSound(bundles.main.urls.daily_game_mix_effect);
                            }
                        }),
                        new cc.Spawn(
                            new cc.MoveTo(0.3, positions[targets[1][id]]),
                            new cc.Sequence(
                                new cc.RotateBy(0.2, rotations[id][1]),
                                new cc.RotateBy(0.1, -rotations[id][1])
                            )
                        ),
                        new cc.DelayTime(0.1),
                        new cc.CallFunc(function () {
                            if (id === 0) {
                                cleverapps.audio.playSound(bundles.main.urls.daily_game_mix_effect);
                            }
                        }),
                        new cc.Spawn(
                            new cc.MoveTo(0.3, positions[targets[2][id]]),
                            new cc.Sequence(
                                new cc.RotateBy(0.2, rotations[id][2]),
                                new cc.RotateBy(0.1, -rotations[id][2])
                            )
                        ),
                        new cc.DelayTime(0.1),
                        new cc.CallFunc(function () {
                            if (id === 0) {
                                cleverapps.audio.playSound(bundles.main.urls.daily_game_mix_effect);
                            }
                        }),
                        new cc.Spawn(
                            new cc.MoveTo(0.3, positions[id]),
                            new cc.Sequence(
                                new cc.RotateBy(0.2, rotations[id][3]),
                                new cc.RotateBy(0.1, -rotations[id][3])
                            )
                        ),
                        new cc.DelayTime(0.3),
                        new cc.CallFunc(function () {
                            if (id === 0) {
                                this.text.runAction(
                                    new cc.FadeIn(0.5)
                                );
                            }
                            f();
                        }.bind(this)),
                        new cc.DelayTime(id * 0.5),
                        new cc.CallFunc(function () {
                            box.setAnimation(0, "animation", true);
                        })
                    ));
                }, this);
            }.bind(this)
        });
    },

    onPressed: function () {
        if (!cleverapps.miniGame.locked) {
            cleverapps.miniGame.open();
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DAILY_BONUS);
            RewardWindow.createMiniGameWindow(this.reward);
            this.close();
        }
    }
});

RewardWindow.createMiniGameWindow = function (reward) {
    return new RewardWindow(reward, {
        title: "MiniGameRewardWindow.title",
        name: "minigamerewardwindow",
        text: "MiniGameRewardWindow.text",
        event: cleverapps.EVENTS.EARN.DAILY
    });
};

cleverapps.styles.MiniGameWindow = {
    size: {
        width: 940,
        height: 700
    },
    margin: 30,

    padding: {
        top: 30,
        bottom: 30
    },

    text: {
        width: 650,
        x: { align: "center" },
        y: { align: "bottom", dy: 30 }
    },

    box: {
        width: 230,
        height: 290,

        x: { align: "center", dx: 10 },
        y: { align: "center" }
    },

    boxes: [
        {
            x: -260,
            y: -180
        },
        {
            x: 0,
            y: 100
        },
        {
            x: 260,
            y: -180
        }
    ],

    animation: {
        x: { align: "center" },
        y: { align: "center", dy: 65 }
    }
};