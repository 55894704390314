/**
 * Created by slava on 3/3/20
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    BLUE: new cc.Color(0, 0, 160, 255),
    YELLOW_TEXT: new cc.Color(255, 208, 0, 255),
    AMOUNT_STROKE_COLOR: new cc.Color(61, 10, 0, 255),
    TITLE_COLOR_YELLOW: new cc.Color(134, 56, 10, 255),
    TITLE_SHADOW: new cc.Color(255, 251, 126, 255)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    EXTRA_HAND_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLUE,
        size: 3
    },
    BUSTER_AMOUNT_STROKE: {
        color: cleverapps.styles.COLORS.AMOUNT_STROKE_COLOR,
        size: 3
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    EXTRA_HAND_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.EXTRA_HAND_TEXT_STROKE
    },
    BOOSTER_BEFORE_AMOUNT_TEXT: {
        name: "default",
        size: 44,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.overrideStyles(cleverapps.styles.RewardGoldView, {
    width: 165,
    height: 86,

    icon: {
        y: 57
    },

    x: { align: "left", dx: 60 },
    y: { align: "top", dy: -130 }
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 165,
    height: 86,

    icon: {
        x: { align: "left", dx: -10 }
    },

    position: [
        {
            x: { align: "left", dx: 40 },
            y: { align: "top", dy: -230 }
        },
        {
            x: { align: "left", dx: 236 },
            y: { align: "top", dy: -130 }
        },
        {
            x: { align: "left", dx: 250 },
            y: { align: "top", dy: -130 }
        }
    ]
});

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    YELLOW_BUTTON_TITLE_SHADOW: new cc.Color(34, 54, 0, 255),
    COLOR_RED: new cc.Color(255, 70, 40, 255),
    EXPIRED_TASK_FONT_COLOR: new cc.Color(0, 0, 0, 255),
    BOOSTER_BEFORE_COINS_PRICE_COLOR: new cc.Color(64, 29, 0, 255),
    HINT_ALARM_COLOR: new cc.Color(150, 150, 150, 255),
    DARK_COLOR: new cc.Color(77, 67, 62)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    IMAGE_FONT_STROKE_THIN: {
        color: cleverapps.styles.COLORS.BLACK_STROKE,
        size: 1
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    EPISODE_TITLE_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.TITLE_COLOR_YELLOW,
        shadow: cleverapps.styles.DECORATORS.TITLE_TEXT_SHADOW
    },
    CONGRATE_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.YELLOW_TEXT,
        stroke: cleverapps.styles.DECORATORS.BROWN_STROKE
    },
    REWARDS_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    LEVEL_CURRENT_TITLE_TEXT: {
        name: "default"
    },

    LOADING_ANIMATION_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.WINDOW_WHITE_TEXT_STROKE
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelView, {
    LevelId: {
        y: {
            dy: -3
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.VictoryWindow, {
    soundDelay: 0.4
});

cleverapps.overrideStyles(cleverapps.styles.MissionAnimationView, {
    upY: 5,
    flyTo: {
        second: {
            y: 5
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyLevelWindow, {
    button: {
        width: 280,
        height: 130
    }
});

cleverapps.overrideStyles(cleverapps.styles.LotteryIcon, {
    positions: {
        "bottom": {
            y: { align: "bottom", dy: -55 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.VkLotteryIcon, {
    positions: {
        "icon": {
            x: { align: "center", dx: -65 },
            y: { align: "bottom", dy: -5 },
            scale: 0.8
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ProgressView, {
    arrows: {
        y: { align: "center", dy: 6 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    debugLevelNo: {
        x: { align: "right", dx: -160 },
        y: { align: "bottom", dy: 100 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        "starterPack0": {
            idleAnimation: "idle1",
            introAnimation: "open1"
        },

        "starterPack": {
            idleAnimation: "idle2",
            introAnimation: "open2"
        },

        "starterPack2": {
            idleAnimation: "idle3",
            introAnimation: "open3"
        }
    },

    button: {
        width: 341,
        height: 130
    },

    rewards: {
        delay: 2,
        duration: 0.675,
        fitTo: {
            width: 140,
            height: 65
        },

        positions: {
            "default": {
                "boosters": {
                    "10": {
                        x: 110,
                        y: 234
                    },
                    "11": {
                        x: -110,
                        y: -50
                    },
                    "12": {
                        x: 106,
                        y: -50
                    }
                },
                "hard": {
                    x: -106,
                    y: 234
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.RestoreProgressButton, {
    size: [
        { width: 180, height: 85 },
        { width: 300, height: 110 },
        { width: 300, height: 110 }
    ],
    position: [
        {
            x: { align: "left", dx: 20 },
            y: { align: "bottom", dy: 20 }
        },
        {
            x: { align: "right", dx: -20 },
            y: { align: "bottom", dy: 20 }
        },
        {
            x: { align: "right", dx: -20 },
            y: { align: "top", dy: -110 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.LoseWindow, {
    livesLeftText: {
        width: 500
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackProductTile, {
    limit: {
        y: -30
    }
});

cleverapps.overrideStyles(cleverapps.styles.BackgroundsWindow, {
    name: false
});
