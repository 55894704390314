/**
 * Created by ivan on 11.04.18.
 */

var MiniGameToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.MINIGAME_BONUS);

    this.countdown = cleverapps.miniGame.countdown;

    cleverapps.miniGame.changeLockedListener = this.changeLocked.bind(this);

    this.changeLocked();
};

MiniGameToolbarItem.prototype = Object.create(ToolbarItem.prototype);
MiniGameToolbarItem.prototype.constructor = MiniGameToolbarItem;

MiniGameToolbarItem.prototype.isVisible = function () {
    return levels.user.checkAvailable(cleverapps.MiniGame.AVAILABLE);
};

MiniGameToolbarItem.prototype.unlock = function () {
    this.mark();
};

MiniGameToolbarItem.prototype.changeLocked = function () {
    if (cleverapps.miniGame.locked) {
        this.disable();
    } else {
        this.enable();
        this.mark();
    }
};

MiniGameToolbarItem.prototype.onClick = function () {
    if (cleverapps.miniGame.locked) {
        cleverapps.notification.create("MiniGame.ClosedText", {
            image: this.icon
        });
        return;
    }

    this.unmark();

    cleverapps.focusManager.display({
        focus: "MiniGameWindowFromToolbar",
        action: function (f) {
            cleverapps.miniGame.openWindow(f);
        }
    });
};